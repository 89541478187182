import { IForm } from '../types';

export const loginFormFields: IForm[] = [
  {
    name: 'login',
    label: 'Логин',
  },

  {
    name: 'password',
    label: 'Пароль',
  },
];
